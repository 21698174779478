import React from 'react'
import classNames from 'classnames'
import Logo from './components/Logo.js'

import { content } from './../../content';
import './styles.scss'

const Top = ({ withoutHero }) => {
    const { logoAlt, logoLink } = content.Top;

    return (<div className={classNames('Top', { 'no-hero': withoutHero })}><header className="container"><Logo alt={logoAlt} link={logoLink} dark={withoutHero} /></header></div>)
}

export default Top;