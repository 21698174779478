import React from 'react'

import { LazyImg } from './..';
import { content } from './../../content';
import './styles.scss'

const Contact = () => {
    const { largeText, columns, image } = content.Contact;

    return (
        <div className="Contact" id="contact">
            <div className="container-xl wrapper">
                <h2 className="header">{largeText}</h2>
                <div className="df">
                    {columns.map((column, index) => <div key={`column${index}`}>
                        <p className="title">{column.title}</p>
                        <p className="text copernicus">
                            {(column.link) ? <a href={column.link}>{column.text}</a> : column.text}
                        </p>
                    </div>)}
                </div>
                <div className="image"><LazyImg src={image} alt={largeText} /></div>
            </div>
        </div>)
}

export default Contact;