import React from 'react'

import { content } from './../../content';
import { LazyImg } from './..';
import './styles.scss'

const Experience = () => {
    const { largeText, text, companyName, logo, name, role } = content.Experience;

    return (
        <div className="Experience">
            <div className="container-xl df">
                <div className="half">
                    <div className="box">
                        <LazyImg src={logo} alt={companyName} />
                        <p className="author">
                            {name}
                            <span className="dimmed" dangerouslySetInnerHTML={{ __html: role }} />
                        </p>
                    </div>
                </div>
                <div className="half df texts">
                    <div>
                        <h2 className="header" dangerouslySetInnerHTML={{ __html: largeText }} />
                        <p className="text copernicus" dangerouslySetInnerHTML={{ __html: `"${text}"` }} />
                    </div>
                </div>


            </div>
        </div>)
}

export default Experience;