import React from 'react'

import { content } from './../../content';
import { LazyImg } from './..';
import './styles.scss'

const Reasons = () => {
    const { largeText, image, numberImage, reasons } = content.Reasons;

    return (
        <div className="Reasons">
            <div className="container-xl wrapper">
                <h2 className="half header">{largeText}</h2>
                <span className="number"><LazyImg src={numberImage} alt={largeText} /></span>
                <div className="reasons df">
                    {reasons.map((reason, index) => <div key={`reason${index}`} className="reason">
                        <p className="title">{reason.title}</p>
                        <p className="text copernicus block">{reason.text}</p>
                    </div>)}
                </div>
                <span className="image"><LazyImg src={image} alt={largeText} /></span>
            </div>
        </div>
    )
}

export default Reasons;