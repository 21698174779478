export const content = {
  SEO: {
    title: 'Appico — Digital Product Design Studio',
    description: 'Appico is a user experience and interface design studio. We help enterprises approach things from the needs of the user and turn complex processes into simple and consumer relatable solutions.'
  },
  Top: {
    logoAlt: 'Appico',
    logoLink: 'https://appico.com'
  },
  Hero: {
    largeText: 'Einfache Erklärvideos <span>für Ihr Produkt</span>',
    buttonText: 'Kostenlose Beratung',
    buttonLink: '#contact',
    videoText: 'Demo Reel',
    videoDuration: '1:12 min',
    videoFile: '/videos/Hero-background-video.mp4',
    videoPoster: '/images/hero/hero-background.jpg',
    fullVideoFileMp4: '/videos/appico-video-showcase.mp4',
    fullVideoFileWebm: '/videos/appico-video-showcase.webm'
  },
  Logotypes: [
    {
      title: 'ZDF',
      image: '/images/logos/zdf.svg',
      link: ''
    },
    {
      title: 'Guess',
      image: '/images/logos/guess.svg',
      link: ''
    },
    {
      title: 'EA',
      image: '/images/logos/ea.svg',
      link: ''
    },
    {
      title: 'UnityMedia',
      image: '/images/logos/unitymedia.svg',
      link: ''
    },
    {
      title: 'Otto',
      image: '/images/logos/otto.svg',
      link: ''
    }
  ],
  HowItWorks: {
    largeText: 'So einfach funktionierts',
    steps: [
      {
        image: '/images/icon-step1.svg',
        title: 'Schritt 1.',
        text: 'Kontakt aufnehmen & Videoidee besprechen'
      },
      {
        image: '/images/icon-step2.svg',
        title: 'Schritt 2.',
        text: 'Transparenter Prozess & gemeinsame Abstimmung'
      },
      {
        image: '/images/icon-step3.svg',
        title: 'Schritt 3.',
        text: 'Eigenes Erklärvideo für jeden Anlass. Ob auf Messe oder Website, alle geht.'
      }
    ]
  },
  VideoGallery: {
    largeText: 'Video Galerie',
    videos: [
      {
        title: 'Attendify Mobile App',
        duration: '1:12 min',
        poster: '/images/posters/identify.jpg',
        videoFileWebm: '/videos/Attendify.webm'
      },
      {
        title: 'Design Bro',
        duration: '1:12 min',
        poster: '/images/posters/design-bro.jpg',
        videoFileMp4: '/videos/DesignBro.mp4',
        videoFileWebm: '/videos/DesignBro.webm'
      },
      {
        title: 'Postly',
        duration: '1:12 min',
        poster: '/images/posters/postly.jpg',
        videoFileMp4: '/videos/Postly.mp4',
        videoFileWebm: '/videos/Postly.webm'
      },
      {
        title: 'Dotcom Therapy',
        duration: '1:12 min',
        poster: '/images/posters/dotcom-therapy.jpg',
        videoFileMp4: '/videos/DotcomTherapy.mp4',
        videoFileWebm: '/videos/DotcomTherapy.webm'
      },
      {
        title: 'Reach Labs',
        duration: '1:12 min',
        poster: '/images/posters/reach-labs.jpg',
        videoFileMp4: '/videos/ReachLabs.mp4',
        videoFileWebm: '/videos/ReachLabs.webm'
      },
      {
        title: 'PIA Group',
        duration: '1:12 min',
        poster: '/images/posters/pia-group.jpg',
        videoFileMp4: '/videos/PIA.mp4',
        videoFileWebm: '/videos/PIA.webm'
      },
    ]
  },
  Reasons: {
    largeText: 'Gute Gründe für ein Erklärvideo',
    image: '/images/animals.png',
    numberImage: '/images/number-6.svg',
    reasons: [
      {
        title: 'Einfach zu verstehen',
        text: 'Ein Bild sagt mehr als tausend Worte. Ein Video leistet aber noch viel mehr. Kurz, spannend & einfach lassen sich so auch komplexe Inhalte kinderleicht vermitteln.'
      },
      {
        title: 'Mehr Reichweite',
        text: 'Mehr als 4 Milliarden User schauen täglich Videos auf Plattformen wie YouTube oder Vimeo. Ihr Erklärvideo lässt sich einfach auf diesen Plattformen veröffentlichen und hilft so dabei Reichweite für ihr Unternehmen oder Produkt zu generieren.'
      },
      {
        title: 'SEO Optimierung',
        text: 'Durch ein Erklärvideo auf Ihrer Website kann die Session Länge der User deutlich erhöht werden, was Google erkennt und mit verbesserten Rankings belohnt.'
      },
      {
        title: 'Vielseitiger Einsatz',
        text: 'Einmal erstellt, kann Ihr Erklärvideo vielseitig eingesetzt werden: Auf Videoportalen, sozialen Netzwerken, Schulungen oder Vor-Ort-Terminen, Fachmessen oder eigenen Firmen-Events. Ein Erklärvideo stellt sicher, dass Ihre Geschichte schnell und einfach rüberkommt.'
      },
      {
        title: 'Individualität',
        text: 'Stechen Sie aus der Masse heraus. Ein hochwertiges und einzigartiges Erklärvideo hilft Ihnen Ihr Unternehmen oder Produkt in einem besonderen Licht zu präsentieren.'
      },
      {
        title: 'Conversion',
        text: 'Schaut sich ein User Ihr Erklärvideo an, steigert das sein Interesse an Ihrem Unternehmen oder Produkt / Service und erhöht die Chance auf einen Abschluss. Der Abschluss kann eine Registrierung/Anmeldung, Kauf oder Kontaktaufnahme sein.'
      },
    ]
  },
  Experience: {
    largeText: 'Kunden<span>feedback<span>',
    text: 'When I need a beautiful video, I&nbsp;come to Appico first.',
    companyName: 'Electronic Arts',
    logo: '/images/logos/ea-white.svg',
    name: 'Nick Boulstridge‏',
    role: 'Electronic Arts, <span>Vice President Media</span>'
  },
  Contact: {
    largeText: 'Jetzt Kontakt aufnehmen',
    image: '/images/grandma.jpg',
    columns: [
      {
        title: 'Telefon',
        text: '+49 40 765 00 50 71 0',
        link: ''
      },
      {
        title: 'Email',
        text: 'video@appico.com',
        link: 'mailto:video@appico.com'
      },
    ]
  },
  Footer: {
    text: 'Appico GmbH. All Rights Reserved',
    linkTitle: 'Impressum',
    link: '#'
  }
}