import React, { useState, useEffect } from 'react'
import classNames from 'classnames';
import { FullscreenPlayer, PlayCircle } from './..';

import { content } from './../../content';
import './styles.scss'

const TIME_TO_FADEIN = 500;

export default function Hero() {

  const [visible, setVisible] = useState(false);
  const { largeText, buttonLink, buttonText, videoText, videoDuration, videoFile, videoPoster, fullVideoFileMp4, fullVideoFileWebm } = content.Hero;
  const styles = { backgroundImage: `url(${videoPoster})` };
  let fadeInTimeout;

  useEffect(() => {
    fadeInTimeout = setTimeout(showHeroTexts, TIME_TO_FADEIN);
    return () => {
      clearTimeout(fadeInTimeout);
    }
  }, [visible]);

  function showHeroTexts() {
    if (!visible) {
      setVisible(true)
    }
  }

  function buttonClick(e) {
    const anchor = (content.Hero.buttonLink.charAt(0) === '#') ? content.Hero.buttonLink.substr(1) : content.Hero.buttonLink;
    document.getElementById(anchor).scrollIntoView({ behavior: 'smooth' });
    e.preventDefault();
  }

  return (
    <div className={classNames('Hero', { 'visible': visible })} style={styles}>
      <div className="container-xl df align">
        <div className="wrapper">
          <h1 className="header" dangerouslySetInnerHTML={{ __html: largeText }} />
          <div className="tools">
            <a href={buttonLink} className="btn btn-main" onClick={buttonClick}>
              <span className="text" data-text={buttonText}>{buttonText}</span>
              <em className="elementFirst" />
              <em className="elementSecond" />
              <em className="elementThird" />
            </a>
            <div className="fulscreen-wrapper">
              <FullscreenPlayer srcMp4={fullVideoFileMp4} srcWebm={fullVideoFileWebm} >
                <span className="video-tools">
                  <span className="icon-wrapper">
                    <PlayCircle className="small" />
                  </span>
                  <span className="video-text">
                    {videoText}
                    <span className="duration dimmed"> {videoDuration} </span>
                  </span>
                </span>
              </FullscreenPlayer>
            </div>
          </div>
        </div>
      </div>
      <div className="video-background">
        <video muted loop autoPlay playsInline poster={videoPoster} >
          <source src={videoFile} type="video/mp4" />
        </video>
        <div className="video-overlay" />
      </div>
      <img src={videoPoster} className="hidden-poster" alt="Appico video poster" />
    </div >
  )

}