import React from 'react'

import { content } from './../../content';
import './styles.scss'

const Footer = () => {
    const { text, link, linkTitle } = content.Footer;
    const year = new Date().getFullYear();

    return (
        <footer className="Footer container">
            <div className="wrapper">
                <span className="copyright"><span>&copy; {year} </span>{text}</span>
                <a href={link}>{linkTitle}</a>
            </div>
        </footer>)
}

export default Footer;