export { default as Layout } from './Layout/Layout.js'
export { default as Top } from './Top/Top.js'
export { default as Hero } from './Hero/Hero.js'
export { default as Logotypes } from './Logotypes/Logotypes.js'
export { default as HowItWorks } from './HowItWorks/HowItWorks.js'
export { default as VideoGallery } from './VideoGallery/VideoGallery.js'
export { default as Reasons } from './Reasons/Reasons.js'
export { default as Experience } from './Experience/Experience.js'
export { default as Contact } from './Contact/Contact.js'
export { default as Footer } from './Footer/Footer.js'
export { default as Portal } from './Portal/Portal.js'
export { default as FullscreenPlayer } from './FullscreenPlayer/FullscreenPlayer.js'
export { default as Overlay } from './Overlay/Overlay.js'
export { default as PlayCircle } from './PlayCircle/PlayCircle.js'
export { default as LazyImg } from './LazyImg/LazyImg.js'
export { default as SEO } from './Seo/Seo.js'
