import React from 'react'

import { LazyImg } from './..';
import { content } from './../../content';
import './styles.scss'

const HowItWorks = () => {
    const { largeText, steps } = content.HowItWorks;

    return (
        <div className="HowItWorks">
            <div className="container-xl">
                <div className="inner-wrapper">
                    <h2 className="header">{largeText}</h2>
                    <div className="steps df">
                        {steps.map((step, index) =>
                            <div key={`step${index}`} className="step">
                                <div className="circle"><LazyImg src={step.image} alt={step.title} /></div>
                                <p className="title">{step.title}</p>
                                <div className="text copernicus block"><p>{step.text}</p></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;