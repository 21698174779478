import React from 'react'

import { content } from './../../content';
import { FullscreenPlayer, PlayCircle, LazyImg } from './..';
import './styles.scss'

const VideoGallery = () => {
  const { largeText, videos } = content.VideoGallery;
  return (
    <div className="VideoGallery">
      <div className="container-xl df">
        <div className="half" />
        <div className="half">
          <h2 className="header">{largeText}</h2>
        </div>
        {videos.map((video, index) => {
          const { poster, title, videoFileMp4, videoFileWebm } = video;
          return (<div key={`video${index}`} className={`half video ${(index % 2) ? 'even' : 'odd'}`}>
            <FullscreenPlayer srcMp4={videoFileMp4} srcWebm={videoFileWebm} >
              <span className="block">
                <div className="wrapper">
                  <div className="fit">
                    <LazyImg src={poster} alt={title} />
                  </div>
                  <div className="hover" />
                </div>
                <div className="info">
                  <div className="icon-wrapper"><PlayCircle /></div>
                </div>
              </span>
            </FullscreenPlayer>
          </div>)
        })}
      </div>
    </div>
  )
}

export default VideoGallery;