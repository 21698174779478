import React, { useState } from 'react'
import { DefaultPlayer } from 'react-html5video';
import classNames from 'classnames';
import 'react-html5video/dist/styles.css';

import { Portal, Overlay } from './..';
import './styles.scss'

const TIME_TO_FADEOUT = 750;
const TIME_TO_FADEIN = 50;

export default function FullscreenPlayer(props) {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const { children, srcMp4, srcWebm } = props;

  function play() {
    setOverlayOpen(true);
    setTimeout(() => {
      setOverlayVisible(true);
      setVideoPlaying(true);
      setIsClosing(false);
      setIsMuted(false);
    }, TIME_TO_FADEIN);
  }

  function close() {
    setIsClosing(true);
    setIsMuted(true);

    setTimeout(() => {
      setOverlayVisible(false);
      setVideoPlaying(false);
      setOverlayOpen(false);
    }, TIME_TO_FADEOUT)
  }

  return (
    <div className="FullscreenPlayer">
      <div onClick={play}>{children}</div>
      {overlayOpen &&
        <Portal rootId="portal">
          <Overlay className={classNames({ 'visible': (overlayVisible), 'closing': (isClosing) })}>
            <div className="video-wrapper">
              {videoPlaying && (
                <DefaultPlayer controls={['PlayPause', 'Seek', 'Volume']} autoPlay muted={(isMuted)}>
                  {srcMp4 && (<source src={srcMp4} type="video/mp4" />)}
                  {srcWebm && (<source src={srcWebm} type="video/webm" />)}
                </DefaultPlayer>
              )}
            </div>
            <button onClick={close} className="close">Close</button>
          </Overlay>
        </Portal>
      }
    </div>
  )
}