import React from 'react'
import './styles.scss'

const PlayCircle = ({ className }) =>
  (
    <span className={`PlayCircle ${className}`}>
      <svg width="54" height="54" viewBox="0 0 54 54">
        <circle className="outer" cx="27" cy="27" r="26" stroke="#ffffff" strokeWidth="1" strokeDasharray="339.292" strokeDashoffset="339.292" />
      </svg>
    </span>
  )


export default PlayCircle;
