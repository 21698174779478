import React from 'react'

import { LazyImg } from './..'
import { content } from './../../content';
import './styles.scss'

const Logotypes = () => {
    const { Logotypes: logos } = content;

    return (
        <div className="Logotypes">
            <div className="container df">
                {logos.map((logo, index) => <div key={`logo${index}`} className="logotype">
                    {logo.link ? <a href={logo.link}><LazyImg src={logo.image} alt={logo.title} /></a> : <LazyImg src={logo.image} alt={logo.title} />}
                </div>)}
            </div>
        </div>)
}

export default Logotypes;